// CODE MIRRORED in /functions/config/env.js

import { isEmpty } from 'lodash';

// firebaseConfig is configuration just for initialization of firebase app
// wsConfig is wonderschool specific settings
// _meta includes debugging information
import configJson from './config.json';

const { firebaseConfig, wsConfig, _meta } = configJson;

const LOCAL = 'local';
const STAGING = 'staging';
const DEV = 'dev';
const PROD = 'prod';
const Environments = {
  [LOCAL]: LOCAL,
  [DEV]: DEV,
  [STAGING]: STAGING,
  [PROD]: PROD,
};

const currentEnv = Environments[wsConfig.envName];
/**
 * @returns {string} current environment name
 */
const getCurrentEnv = () => {
  return currentEnv;
};

type ProjectMetaData = {
  _project: string;
  _app: string;
  _hosting: string;
};

type FirebaseOptions = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string; // staging does not have analytics enabled as of 2025-02-28
};

type ProjectConfig = {
  functionsDomain: string;
  ccmsFunctionsDomain: string;
  ccmsDomain: string;
  accountsDomain: string;
  onboardingDomain: string;
  authApiDomain: string;
  parentWebUrl: string;
  secretsProject: string;
  envName: string;
  cookieSecret: string;
  defaultReturn: string;
  rollbar: {
    accessToken: string;
    environment: string;
  };
  launchDarklyClientId: string;
  gtmId: string;
};

export type AppConfig = {
  _meta: ProjectMetaData;
  firebaseConfig: FirebaseOptions;
  wsConfig: ProjectConfig;
};

/**
 * configuration for specified env, from file copied during build step
 * see package.json
 */
const getConfig = (): AppConfig => {
  return { _meta, firebaseConfig, wsConfig };
};

const isLocal = (env: string = getCurrentEnv()): boolean => env === LOCAL;
const isDev = (env: string = getCurrentEnv()): boolean => env === DEV;
const isStaging = (env: string = getCurrentEnv()): boolean => env === STAGING;
const isProd = (env: string = getCurrentEnv()): boolean => env === PROD;
const isTest = (env: string = getCurrentEnv()): boolean =>
  (isLocal(env) && isEmpty(process.env.REACT_APP_GIT_SHA)) ||
  process.env.REACT_APP_IS_TEST === 'true';

/**
 * whether the emulation env variable has been set
 */
const shouldEmulateFirebase = (): boolean =>
  process.env.REACT_APP_FIREBASE_EMULATOR === 'ACTIVE';

const SELF_SERVE_ONBOARDING_URL =
  process.env.REACT_APP_SELF_SERVE_ONBOARDING_URL;
const getSelfServeOnboardingUrl = () => {
  return (
    SELF_SERVE_ONBOARDING_URL ||
    getConfig().wsConfig.onboardingDomain ||
    'https://onboarding.wonderschool.com'
  );
};

const getGtmId = () => {
  return getConfig()?.wsConfig?.gtmId || undefined;
};

export {
  LOCAL,
  DEV,
  PROD,
  Environments,
  getCurrentEnv,
  getConfig,
  isLocal,
  isDev,
  isStaging,
  isProd,
  isTest,
  shouldEmulateFirebase,
  getSelfServeOnboardingUrl,
  getGtmId,
};
