import { DocumentData } from 'firebase/firestore';

import { docOnListen } from './firestore';
import { makeFunctionsRequest } from './functions';

const paths = {
  /**
   * @param invitationId
   * @returns doc path
   */
  invitation: (invitationId: string): string => `invitations/${invitationId}`,
};

/**
 * Listens for updates to an invitation doc by the specified id
 * If invitation doesn't exist, callback is called with undefined
 * @param invitationId
 * @param next callback passed User object https://firebase.google.com/docs/reference/js/auth.user.md#user_interface
 * @param error callback triggered on error
 * @returns unsubscribe function
 */
export const listenForInvitation = (
  invitationId: string | undefined,
  next: (doc: DocumentData | undefined) => object | void,
  error: (err: Error) => object | void
) => {
  if (!invitationId) {
    throw new Error('invitationId required');
  }
  return docOnListen(paths.invitation(invitationId), next, error);
};

export const getPendingUserInvite = async (email: string) => {
  return makeFunctionsRequest('/endpoints-getPendingUserInvite', {
    email,
  });
};
