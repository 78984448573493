import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth as getAuthF } from 'firebase/auth';
import {
  connectFirestoreEmulator,
  getFirestore as getFirestoreF,
  initializeFirestore,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions as getFunctionsF,
} from 'firebase/functions';

import { getConfig, isLocal, isTest, shouldEmulateFirebase } from './env';

const { firebaseConfig, wsConfig } = getConfig();

/**
 * @typedef {import('firebase/app').FirebaseApp} FirebaseApp
 * @typedef {import('firebase/auth').Auth} Auth
 * @typedef {import('firebase/firestore').Firestore} Firestore
 * @typedef {import('firebase/functions').Functions} Functions
 */

/**
 * @type {FirebaseApp}
 */
let firebase;
/**
 * @type {Auth}
 */
let auth;
/**
 * @type {Firestore}
 */
let firestore;
/**
 * @type {Functions}
 */
let functions;
/**
 * @type {string} domain name and base path for functions endpoints
 * @example //127.0.0.1:5050/moxit-wonderschool-dev/us-central1
 */
const functionsDomain = wsConfig.functionsDomain;
const ccmsDomain = wsConfig.ccmsDomain;
const parentWebUrl = wsConfig.parentWebUrl;
const ccmsFunctionsDomain = wsConfig.ccmsFunctionsDomain;

const initAuth = (fireApp) => {
  return getAuthF(fireApp);
};
const initFirestore = (fireApp) => {
  initializeFirestore(fireApp, {
    // https://firebase.google.com/docs/reference/js/firestore_.firestoresettings.md#firestoresettings_interface
    ignoreUndefinedProperties: true,
  });
  return getFirestoreF(fireApp);
};

const initFunctions = (fireApp) => {
  return getFunctionsF(fireApp);
};

export const initFirebase = () => {
  // Only initialize if it isn't already initialized.
  if (!firebase) {
    firebase = initializeApp(firebaseConfig);
    auth = initAuth(firebase);
    firestore = initFirestore(firebase);
    functions = initFunctions(firebase);

    if (isLocal() && !isTest()) {
      // logger not yet loaded here so we have to use normal one
      console.log('🔥  🔥  🔥 FIREBASE INITIALIZED 🔥  🔥  🔥');
    }
    /**
     * To use firebase emulator
     * start firebase emulator: firebase emulators:start
     * start react app: yarn start:with-emulator
     */
    if (shouldEmulateFirebase()) {
      // Auth Emulator
      connectAuthEmulator(auth, 'http://127.0.0.1:9099', {
        disableWarnings: isTest(),
      });
      // Firestore Emulator
      connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
      // Functions Emulator
      connectFunctionsEmulator(functions, '127.0.0.1', 5050);
    }
  }
  return { auth, firestore, functions };
};

const getAuth = () => auth || initFirebase().auth;
const getFirestore = () => firestore || initFirebase().firestore;
const getFunctions = () => functions || initFirebase().functions;

initFirebase(); // run at least once

export {
  getAuth,
  getFirestore,
  getFunctions,
  functionsDomain,
  ccmsDomain,
  parentWebUrl,
  ccmsFunctionsDomain,
};
