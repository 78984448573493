import { Invitation } from '@wonderschool/common-base-types';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { listenForInvitation } from '../api/firebase/invitations';
import { ccmsDomain, parentWebUrl } from '../config/init';
import { logWarning } from '../providers/rollbar';
import { decodeBase64UrlJson } from '../utils/hashParams';

export const useInvitationPayload = (): {
  invitationData?: Invitation;
  isValidating: boolean;
  validationError?: Error;
} => {
  const { invitationHash } = useParams();
  const [isValidating, setIsValidating] = useState(true);
  const [validationError, setValidationError] = useState<Error | undefined>();

  const invitationData = useMemo(() => {
    if (!invitationHash) {
      return;
    }
    try {
      const decoded: Invitation = decodeBase64UrlJson(invitationHash);
      if (!decoded.id) throw new Error('Invalid invitation hash');
      setIsValidating(false);
      return decoded;
    } catch (err) {
      logWarning(`Invalid invitation hash: ${err && (err as Error).message}`, {
        err,
      });
      setIsValidating(false);
      setValidationError(err as Error);
    }
  }, [invitationHash]);

  return {
    invitationData,
    isValidating,
    validationError,
  };
};

export const useInvitationListener = (invitationId?: string) => {
  const [invitationStatus, setInvitationStatus] = useState('pending');
  const [inviteError, setInviteError] = useState<Error | undefined>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    // sets unsubscribe
    return listenForInvitation(
      invitationId,
      (invitation) => {
        setInvitationStatus(invitation?.status || 'pending');
      },
      (err) => {
        logWarning(
          `Invite Status Error: ${err.message} occurred during listen to invitation with ID: [${invitationId}]`,
          { err }
        );
        setInviteError(err);
      }
    );
  }, [invitationId]);

  useEffect(() => {
    if (inviteError) {
      setIsError(true);
    }
    if (!isError) {
      if (invitationStatus === 'completed') {
        setIsCompleted(true);
      }
      if (invitationStatus === 'pending') {
        setIsLoading(true);
      }
    }
    if (invitationStatus === 'archived') {
      setIsArchived(true);
    }
  }, [inviteError, isError, invitationStatus]);

  return {
    invitationStatus,
    inviteError,
    isInviteLoading: isLoading,
    isInviteCompleted: isCompleted,
    isInviteError: isError || isArchived,
    staffRedirectUrlToCcms: `${ccmsDomain}/auth-return`,
    parentRedirectUrlToUPE: parentWebUrl,
  };
};
