import { INVITATION_TYPES } from '@wonderschool/common-base-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../components/Forms/Parts/v2/Button';
import FormHeader from '../components/Forms/Parts/v2/FormHeader';
import Loader from '../components/LoaderV2';
import useAuthUser from '../providers/auth/useAuthUserV2';
import { useTranslation } from '../providers/i18next';
import { useInvitationListener } from '../providers/invitations';
import { RETURN_URL_KEY, useReturnValue } from '../providers/returnValue';
import { RETURN_ROUTE_V2, SIGNOUT_ROUTE } from '../routes/constants';
import PageBaseV2 from './PageLayout/PageBaseV2';

// Slowing down transition as typically such a quick
// transition it is jarring for the user.
const ARBITRARY_WAITING_TIME_IN_MS_BEFORE_REDIRECTION = 500;

const InvitationStatusPage = () => {
  const { txn } = useTranslation();
  const navigate = useNavigate();
  const { returnValue, replaceReturnValue } = useReturnValue();

  const { state: { invitationType, invitationId } = {} } = useLocation();

  const {
    isInviteCompleted,
    isInviteError,
    staffRedirectUrlToCcms,
    parentRedirectUrlToUPE,
  } = useInvitationListener(invitationId);
  const { isEmailVerified } = useAuthUser();

  useEffect(() => {
    if (isInviteCompleted && isEmailVerified) {
      replaceReturnValue({
        ...returnValue,
        [RETURN_URL_KEY]:
          invitationType === INVITATION_TYPES.PARENT
            ? parentRedirectUrlToUPE
            : staffRedirectUrlToCcms,
      });

      setTimeout(() => {
        navigate(RETURN_ROUTE_V2, { replace: true });
      }, ARBITRARY_WAITING_TIME_IN_MS_BEFORE_REDIRECTION);
    }
  }, [
    isInviteCompleted,
    isEmailVerified,
    staffRedirectUrlToCcms,
    navigate,
    replaceReturnValue,
    returnValue,
    parentRedirectUrlToUPE,
    invitationType,
  ]);

  // invite fail
  if (isInviteError) {
    return (
      <PageBaseV2 titleBase={txn('Preparing account')}>
        <div className="space-y-10">
          <FormHeader
            text={txn('It looks like this is an invalid invitation.')}
            subtext={txn('Please click the button below to sign in.')}
          />
          <Button
            onClick={() => navigate(SIGNOUT_ROUTE, { replace: true })}
            text={txn('Sign in')}
          />
        </div>
      </PageBaseV2>
    );
  }

  return (
    <PageBaseV2 titleBase={txn('Preparing account')}>
      <Loader text={txn('One moment while we get everything ready')} />
    </PageBaseV2>
  );
};

export default InvitationStatusPage;
