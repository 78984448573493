// import SignupFormV2 from '../components/Forms/v2/SignupForm';
// import LoadingSpinner from '../components/LoadingSpinner';
import { Button } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import SignupFormV2 from '../components/Forms/v2/SignupForm';
import { useTranslation } from '../providers/i18next';
import { useInvitationPayload } from '../providers/invitations';
import { SIGNOUT_ROUTE } from '../routes/constants';
import PageBaseV2 from './PageLayout/PageBaseV2';

// const Validating = () => {
//   return null;
//   // const { txn } = useTranslation();
//   // return (
//   //   <Center>
//   //     <Stack>
//   //       <LoadingSpinner />

//   //       <Text>{txn('Validating invitation')}...</Text>
//   //     </Stack>
//   //   </Center>
//   // );
// };

const InvalidInvitation = () => {
  const { txn } = useTranslation();

  return (
    <div>
      <h1>{txn('It looks like this is an invalid invitation.')}</h1>

      <span>{txn('Please click the button below to sign in.')}</span>

      <Button
        variant="solid"
        colorScheme="blue"
        size="lg"
        as={NavLink}
        to={SIGNOUT_ROUTE}
        replace
      >
        {txn('Sign in')}
      </Button>
    </div>
  );
};

const InvitationSignupPage = () => {
  const { txn } = useTranslation();
  const { invitationData, isValidating, validationError } =
    useInvitationPayload();

  if (!isValidating && (!invitationData?.id || validationError)) {
    // TODO: convert this to tailwind
    return <InvalidInvitation />;
  }

  return (
    <PageBaseV2 titleBase={txn('Sign Up')}>
      <SignupFormV2 invitation={invitationData} />
    </PageBaseV2>
  );
};

export default InvitationSignupPage;
