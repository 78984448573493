// CODE MIRRORED IN functions/api/firestore.js (v9)
// This is using Firebase v9 syntax

import {
  collection,
  deleteDoc as deleteDocFirestore,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  writeBatch,
} from 'firebase/firestore';

import { getFirestore } from '../../config/init';
import { logError } from '../../providers/rollbar';

/**
 * @typedef {import('firebase/firestore').DocumentReference} DocumentReference
 * @typedef {import('firebase/firestore').CollectionReference} CollectionReference
 * @typedef {import('firebase/firestore').WriteBatch} WriteBatch
 * @typedef {import('firebase/firestore').DocumentData} DocumentData
 */

/**
 * @param {string} path
 * @returns {DocumentReference}
 */
const getDocRef = (path, firestoreCtx = getFirestore()) =>
  doc(firestoreCtx, path);
/**
 * @param {string} path
 * @returns {CollectionReference}
 */
const getCollectionRef = (path, firestoreCtx = getFirestore()) =>
  collection(firestoreCtx, path);
/**
 * A container for collecting batch requests
 * @returns {WriteBatch}
 */
const getBatchRef = (firestoreCtx = getFirestore()) => writeBatch(firestoreCtx);
/**
 * ### Async / Promise
 * Commit the batch requests
 * @param {WriteBatch} batch
 * @returns {Promise<boolean>}
 * if undefined, an error occurred. otherwise, it returns true
 * only succeeds if all writes are committed.
 */
const finalizeBatch = async (batch) => {
  if (!batch) {
    throw new Error('batch is required');
  }
  try {
    await batch.commit();
    return true;
  } catch (err) {
    logError(`could not commit batch transaction: ${err.message}`, err, {
      err,
      batch,
    });
    return; // signals failure to write
  }
};
/**
 * ### Async / Promise
 * Write new data to a document using a customizable write strategy.
 * Defaults to merge
 * Accepts a batch parameter.
 * @param {string} docPath document path
 * @param {Object} data data to be written
 * @param {boolean} merge whether to use merge write strategy
 * @param {WriteBatch?} optBatch provide this if you'd prefer to commit as a batch operation
 * @returns {Promise<boolean | WriteBatch | undefined>}
 * if undefined, an error occurred. otherwise, it returns true
 * or the batch object if provided
 */
const writeDoc = async (
  docPath,
  data,
  merge = true,
  optBatch,
  firestoreCtx = getFirestore()
) => {
  try {
    const docRef = getDocRef(docPath, firestoreCtx);
    if (optBatch) {
      optBatch.set(docRef, data, { merge });
      return optBatch;
    } else {
      await setDoc(docRef, data, { merge });
      return true;
    }
  } catch (err) {
    logError(`Failed to write doc: ${err.message}`, err, {
      err,
      docPath,
      data,
    });
    return; // signals failure to write
  }
};

/**
 * ### Async / Promise
 * Write data to a document and _overwrites_ what was previously there.
 * Accepts a batch parameter.
 * @param {string} docPath document path
 * @param {Object} data data to be written
 * @param {WriteBatch?} optBatch provide this if you'd prefer to commit as a batch operation
 * @returns {Promise<boolean | WriteBatch | undefined>}
 * if undefined, an error occurred. otherwise, it returns true
 * or the batch object if provided
 */
const overwriteDoc = async (
  docPath,
  data,
  optBatch,
  firestoreCtx = getFirestore()
) => {
  return writeDoc(docPath, data, false, optBatch, firestoreCtx);
};
/**
 * ### Async / Promise
 * Write new data to a document using a merge strategy.
 * Accepts a batch parameter.
 * @param {string} docPath document path
 * @param {Object} data data to be written
 * @param {WriteBatch?} optBatch provide this if you'd prefer to commit as a batch operation
 * @returns {Promise<boolean | WriteBatch | undefined>}
 * if undefined, an error occurred. otherwise, it returns true
 * or the batch object if provided
 */
const writeMergeDoc = async (
  docPath,
  data,
  optBatch,
  firestoreCtx = getFirestore()
) => {
  return writeDoc(docPath, data, true, optBatch, firestoreCtx);
};
/**
 * ### Async / Promise
 * deletes an entire document
 * @param {string} docPath document path
 * @param {WriteBatch?} optBatch provide this if you'd prefer to commit as a batch operation
 * @return {Promise<boolean | WriteBatch | undefined>}
 * if undefined, an error occurred. otherwise, it returns true
 * or the batch object if provided
 */
const deleteDoc = async (docPath, optBatch, firestoreCtx = getFirestore()) => {
  try {
    const docRef = getDocRef(docPath, firestoreCtx);
    if (optBatch) {
      optBatch.delete(docRef);
      return optBatch;
    } else {
      await deleteDocFirestore(docRef);
      return true;
    }
  } catch (err) {
    logError(`Failed to delete doc: ${err.message}`, err, {
      err,
      docPath,
      optBatch,
    });
    return; // signals failure to delete
  }
};
/**
 * ### Async / Promise
 * Gets document as json object
 * @param {string} docPath
 * @returns {Promise<DocumentData | undefined | null>}
 * if undefined, an error occurred during reading.
 * if null, the document was not found.
 * otherwise, returns the document data as a json object
 */
const getDocument = async (docPath, firestoreCtx = getFirestore()) => {
  try {
    const docRef = getDocRef(docPath, firestoreCtx);
    const snapshot = await getDoc(docRef);
    // snapshot.data() would be undefined if !snapshot.exists()
    return snapshot.data() || null; // subtly distinguish between failure case
  } catch (err) {
    logError(`Failed to get doc: ${err.message}`, err, { err, docPath });
    return; // signals failure to read.
  }
};
/**
 * ### Async / Promise
 * Returns all found (w/o error) documents in a collection as json objects in an array
 * @param {string} collectionPath
 * @returns {Promise<DocumentData[] | undefined>}
 * if undefined, an error occurred during reading that prevented any data from being returned
 * if the array is empty, either no documents exist or there was some issue fetching them all
 * otherwise, an array of json objects of as many documents as were successfully found
 */
const getCollectionDocs = async (
  collectionPath,
  firestoreCtx = getFirestore()
) => {
  try {
    const collectionRef = getCollectionRef(collectionPath, firestoreCtx);
    const collectionDocs = await getDocs(collectionRef);
    return collectionDocs.docs
      .map((snapshot) => {
        return snapshot.data();
      })
      .filter((x) => !!x);
  } catch (err) {
    logError(`Failed to get collection: ${err.message}`, err, {
      err,
      collectionPath,
    });
    return; // signals failure to read
  }
};

/**
 * listens for updates to the document specified at docPath, callback triggers at least once
 * https://firebase.google.com/docs/reference/js/firestore_#onsnapshot_3
 * if no document exists in that location, `next` is called with undefined
 * @param {string} docPath location of document
 * @param {(doc: DocumentData | undefined) => object | void} next callback passed User object https://firebase.google.com/docs/reference/js/auth.user.md#user_interface
 * @param {(err: Error) => object | void} error callback triggered on error
 * @returns unsubscribe function
 */
const docOnListen = (docPath, next, error, firestoreCtx = getFirestore()) => {
  try {
    const docRef = getDocRef(docPath, firestoreCtx);
    const unsubscribe = onSnapshot(
      docRef,
      (doc) =>
        next(
          doc.exists()
            ? {
                id: doc.id,
                ...doc.data(),
              }
            : undefined
        ),
      error
    );
    return unsubscribe;
  } catch (err) {
    logError(`Failed to listen to doc: ${err.message}`, err, { err, docPath });
    return; // signals failure to listen
  }
};

export {
  getDocRef,
  getCollectionRef,
  deleteDoc,
  getBatchRef,
  finalizeBatch,
  getDocument,
  getCollectionDocs,
  docOnListen,
  writeMergeDoc,
  overwriteDoc,
};
