import { yupResolver } from '@hookform/resolvers/yup';
import { Invitation } from '@wonderschool/common-base-types';
import InputMask from 'comigo-tech-react-input-mask'; // https://github.com/sanniassin/react-input-mask/issues/239
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { dataTestIds } from '../../../config/data-testids';
import { getSelfServeOnboardingUrl } from '../../../config/env';
import { useAuthState } from '../../../providers/authState';
import { useTranslation } from '../../../providers/i18next';
import {
  RETURN_LANGUAGE_KEY,
  useReturnValue,
} from '../../../providers/returnValue';
import { logError, logWarning } from '../../../providers/rollbar';
import {
  INVITATION_STATUS_ROUTE,
  RETURN_ROUTE,
} from '../../../routes/constants';
import { checkHasBrandingData } from '../../../utils/checkHasBrandingData';
import Loader from '../../LoaderV2';
import { PLACEHOLDERS } from '../consts';
import useSignup from '../Hooks/useSignup';
import LegalTextV2 from '../Parts/LegalTextV2';
import Button from '../Parts/v2/Button';
import Checkbox from '../Parts/v2/Checkbox';
import FormField from '../Parts/v2/FormField';
import FormHeader from '../Parts/v2/FormHeader';
import FormInput from '../Parts/v2/FormInput';
import { styles } from '../Parts/v2/styles';
import {
  ExtendedError,
  FORM_FIELD_NAMES,
  UserCredentialsFormSchema,
} from '../types';

const onboardUrl = getSelfServeOnboardingUrl();

interface Props {
  invitation?: Invitation;
}

const SignupForm = ({ invitation }: Props) => {
  const { txn, languageKey } = useTranslation();
  const navigate = useNavigate();
  const { signUpNewUser, signUpInvitedUser } = useAuthState();
  const { formDefaults, brandingData, getReturnState, returnValue } =
    useReturnValue();
  const { userCredentialsFormSchema } = useSignup();
  const methods = useForm<UserCredentialsFormSchema>({
    resolver: yupResolver(userCredentialsFormSchema),
    // Validate on submit, we're doing this because
    // network code validator requires a network request.
    mode: 'onSubmit',
  });
  const [loading, setLoading] = useState(false);
  const [unknownFormError, setUnknownFormError] = useState(false);

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
    setValue,
    setError,
    reset,
  } = methods;

  const hasBranding = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  const hasInvitation = !!invitation?.id && !!invitation?.email;

  const returnState = useMemo(() => getReturnState(), [getReturnState]);

  // ensure user context exists
  useEffect(() => {
    const userContext = {
      returnState,
      returnValue,
      invitation,
    };
    const noUserContextFound =
      !Object.keys(userContext.returnState || {}).length &&
      !userContext.returnValue &&
      !hasInvitation;

    if (noUserContextFound) {
      if (onboardUrl) {
        window.location.replace(onboardUrl);
      }
    }
  }, [returnState, returnValue, hasInvitation, invitation]);

  // check for and instantiate newSupply flag + email
  useEffect(() => {
    if (!returnState || !Object.keys(returnState || {}).length) {
      return;
    }

    setValue(FORM_FIELD_NAMES.NEW_SUPPLY, returnState?.newSupply ?? false);
    setValue(FORM_FIELD_NAMES.EMAIL, returnState?.email ?? '');
  }, [returnState, setValue, returnValue]);

  // hydrate form with invite data
  useEffect(() => {
    if (!invitation) {
      return;
    } else if (!invitation?.id || !invitation?.email) {
      logWarning('Unexpected: invitation without id/email', { invitation });
      return;
    }

    // Populate form with invitation data if available
    const {
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
      id = '',
    } = invitation;

    // must use reset here, rather than setValue
    // because the phone mask will not work as intended otherwise
    reset({
      [FORM_FIELD_NAMES.FIRST_NAME]: firstName,
      [FORM_FIELD_NAMES.LAST_NAME]: lastName,
      [FORM_FIELD_NAMES.PHONE]: phone,
      [FORM_FIELD_NAMES.EMAIL]: email,
      [FORM_FIELD_NAMES.INVITATION_ID]: id,
    });
    // TODO: REMOVE THE NEXT TWO LINES
    setValue(FORM_FIELD_NAMES.NETWORK_CODE, 'test value');
    setValue(FORM_FIELD_NAMES.NEW_SUPPLY, false);
    // TODO: REMOVE setValue as a dependency
  }, [invitation, reset, setValue]);

  const onSubmit = handleSubmit(async (data: UserCredentialsFormSchema) => {
    const signupData: UserCredentialsFormSchema & {
      type?: string;
    } = {
      ...data,
      // always ensure lowercase
      email: data.email.toLocaleLowerCase(),
    };

    try {
      setLoading(true);

      const optData = {
        [RETURN_LANGUAGE_KEY]: languageKey,
      };

      let signupResult: ExtendedError | null = null;

      // Handles signup with invitation.
      if (hasInvitation || invitation) {
        // Send invitation id for post signup user profile update.
        signupData.invitationId = invitation?.id;
        // invites should not be considered newSupply
        signupData.newSupply = false;
        // we MUST ensure that return context does not override the invite type.
        if (invitation.type) {
          signupData.type = invitation.type;
        }
        signupResult = await signUpInvitedUser(data, optData);
      } else {
        signupResult = await signUpNewUser(data, optData, hasBranding);
      }

      // primarily concerned with catching in-use email errors
      if (!signupResult || signupResult?.errorCode) {
        if (signupResult?.errorCode === 'email') {
          setError(FORM_FIELD_NAMES.EMAIL, {
            message: txn('This email address is already in use'),
          });
          setLoading(false);
          return null;
        }
        // unknown issue (caught below)
        throw new Error('Signup failed');
      }

      if (hasInvitation) {
        navigate(INVITATION_STATUS_ROUTE, {
          replace: true,
          state: {
            ...data,
            invitationType: invitation?.type,
            invitationId: invitation?.id,
          },
        });
      } else {
        // Normal return
        navigate(RETURN_ROUTE, { replace: true });
      }
    } catch (err: any) {
      logError(`SignUpForm: ${err.message}`, err);
      setUnknownFormError(true);
      setLoading(false);
    }
  });

  // local loading screen which matches that of the invitation-status page.
  if (loading) {
    return (
      <Loader
        hasBranding={hasBranding}
        text={txn('One moment while we get everything ready')}
      />
    );
  }

  // catch all for broken states
  if (unknownFormError) {
    return (
      <FormHeader
        text={txn('Oops, something went wrong')}
        subtext={txn('We could not create your account')}
      />
    );
  }

  return (
    <div className="space-y-10">
      {/* IMPORTANT: if there is alternate branding, no mention of wonderschool is permitted */}
      <div>
        <FormHeader
          text={
            !hasBranding && hasInvitation
              ? txn('{{firstName}}, you have an invitation!', {
                  firstName: invitation?.firstName,
                })
              : txn('Finish setting up your account')
          }
          subtext={
            !hasBranding && hasInvitation
              ? txn('Welcome to {{organizationName}}.', {
                  organizationName: invitation.organizationName,
                })
              : txn('Just a few more details before you get started.')
          }
        />
      </div>
      <div className="space-y-4">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} noValidate className="space-y-6">
            {/* hidden inputs carried forward... */}
            {/* note: email input is generally hidden, but handled below */}
            <FormInput
              hidden
              type="checkbox"
              {...register(FORM_FIELD_NAMES.NEW_SUPPLY)}
              data-testid={dataTestIds.signupForm.newSupplyCheckbox}
            />
            <FormInput
              hidden
              type="input"
              {...register(FORM_FIELD_NAMES.INVITATION_ID)}
              data-testid={dataTestIds.signupForm.invitationId}
            />

            <div className="flex gap-x-4 w-full">
              <div className="w-full">
                <FormField
                  // hide for invite
                  {...(invitation?.id ? { hidden: true } : {})}
                  label={PLACEHOLDERS[FORM_FIELD_NAMES.FIRST_NAME]}
                  required
                  placeholder={PLACEHOLDERS[FORM_FIELD_NAMES.FIRST_NAME]}
                  disabled={isSubmitting}
                  defaultValue={formDefaults?.firstName}
                  error={errors.firstName}
                  {...register(FORM_FIELD_NAMES.FIRST_NAME)}
                  data-testid={dataTestIds.signupForm.firstName}
                />
              </div>
              <div className="w-full">
                <FormField
                  // hide for invite
                  {...(invitation?.id ? { hidden: true } : {})}
                  label={PLACEHOLDERS[FORM_FIELD_NAMES.LAST_NAME]}
                  required
                  placeholder={PLACEHOLDERS[FORM_FIELD_NAMES.LAST_NAME]}
                  disabled={isSubmitting}
                  defaultValue={formDefaults?.lastName}
                  error={errors.lastName}
                  {...register(FORM_FIELD_NAMES.LAST_NAME)}
                  data-testid={dataTestIds.signupForm.lastName}
                />
              </div>
            </div>

            <FormField
              label={PLACEHOLDERS[FORM_FIELD_NAMES.PHONE]}
              placeholder={PLACEHOLDERS[FORM_FIELD_NAMES.PHONE]}
              type="tel"
              disabled={isSubmitting}
              defaultValue={formDefaults?.phone}
              error={errors.phone}
              {...register(FORM_FIELD_NAMES.PHONE)}
              // note: these attributes must come after spreading register
              onBlur={(e) => {
                // validate password confirm on blur
                const value = e?.currentTarget?.value || e?.target?.value;
                setValue(FORM_FIELD_NAMES.PHONE, value, {
                  shouldValidate: true,
                });
              }}
              // clears active error state on input delete
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.currentTarget.value || e.target.value;
                if (errors[FORM_FIELD_NAMES.PHONE]) {
                  setValue(FORM_FIELD_NAMES.PHONE, value, {
                    shouldValidate: true,
                  });
                } else {
                  setValue(FORM_FIELD_NAMES.PHONE, value);
                }
              }}
              as={InputMask}
              mask={'(999) 999-9999'}
              data-testid={dataTestIds.signupForm.mobile}
            />

            {/* 
              hide this input for standard signups, 
              networks signups via join pages wont have this info, so the user must be able to enter it
            */}
            {invitation?.email || returnState?.email ? (
              <FormInput
                hidden
                type="email"
                {...register(FORM_FIELD_NAMES.EMAIL)}
                data-testid={dataTestIds.signupForm.email}
              />
            ) : (
              <FormField
                label={PLACEHOLDERS[FORM_FIELD_NAMES.EMAIL]}
                required
                placeholder={PLACEHOLDERS[FORM_FIELD_NAMES.EMAIL]}
                type="email"
                disabled={isSubmitting}
                defaultValue={formDefaults?.email}
                error={errors.email}
                {...register(FORM_FIELD_NAMES.EMAIL)}
                data-testid={dataTestIds.signupForm.email}
              />
            )}

            <div className="space-y-2">
              <FormField
                label={PLACEHOLDERS[FORM_FIELD_NAMES.PASSWORD]}
                required
                placeholder={PLACEHOLDERS[FORM_FIELD_NAMES.PASSWORD]}
                type="password"
                autoComplete="new-password"
                disabled={isSubmitting}
                error={errors.password}
                {...register(FORM_FIELD_NAMES.PASSWORD)}
                // note: this attribute must come after spreading register
                onBlur={(e) => {
                  // validate password confirm on blur
                  const value = e?.currentTarget?.value || e?.target?.value;
                  setValue(FORM_FIELD_NAMES.PASSWORD, value, {
                    shouldValidate: true,
                  });
                }}
                onChange={(e) => {
                  const value = e?.currentTarget?.value || e?.target?.value;
                  if (errors[FORM_FIELD_NAMES.PASSWORD]) {
                    setValue(FORM_FIELD_NAMES.PASSWORD, value, {
                      shouldValidate: true,
                    });
                  } else {
                    setValue(FORM_FIELD_NAMES.PASSWORD, value);
                  }
                }}
                data-testid={dataTestIds.signupForm.password}
              />
              <div className="text-xs">
                {txn(
                  'Password must be 6 or more characters. Password should contain an uppercase letter, lowercase letter, number, and symbol.'
                )}
              </div>
            </div>

            <FormField
              label={PLACEHOLDERS[FORM_FIELD_NAMES.PASSWORD_CONFIRM]}
              required
              placeholder={PLACEHOLDERS[FORM_FIELD_NAMES.PASSWORD_CONFIRM]}
              type="password"
              autoComplete="new-password"
              disabled={isSubmitting}
              error={errors.passwordConfirm}
              {...register(FORM_FIELD_NAMES.PASSWORD_CONFIRM)}
              // note: this attribute must come after spreading register
              onBlur={(e) => {
                // validate password confirm on blur
                const value = e?.currentTarget?.value || e?.target?.value;
                setValue(FORM_FIELD_NAMES.PASSWORD_CONFIRM, value, {
                  shouldValidate: true,
                });
              }}
              onChange={(e) => {
                const value = e?.currentTarget?.value || e?.target?.value;
                if (errors[FORM_FIELD_NAMES.PASSWORD_CONFIRM]) {
                  setValue(FORM_FIELD_NAMES.PASSWORD_CONFIRM, value, {
                    shouldValidate: true,
                  });
                } else {
                  setValue(FORM_FIELD_NAMES.PASSWORD_CONFIRM, value);
                }
              }}
              data-testid={dataTestIds.signupForm.passwordConfirm}
            />

            <Checkbox
              required
              error={errors[FORM_FIELD_NAMES.TERMS_AND_CONDITIONS]}
              {...register(FORM_FIELD_NAMES.TERMS_AND_CONDITIONS)}
              data-testid={dataTestIds.signupForm.termsAndConditions}
            >
              <LegalTextV2 />
            </Checkbox>

            <div className="pt-16 space-y-4">
              <Button type="submit" text={txn('Next')} />
              <div className={styles.btnLink} onClick={() => history.back()}>
                {txn('Go back')}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default SignupForm;
